import { graphql } from 'gatsby';
import React from 'react';

import Hero from '../shared/components/Hero/index';
import TyneBridge from '../shared/images/banners/tyne-bridge.jpg';
import Layout from '../shared/components/Layouts/Inner';
import ContainerFixed from '../shared/components/Layouts/ContainerFixed';
import SEO from '../shared/components/SEO';

import PressReviews from '../shared/components/PressReviews';
import AppDownload from '../shared/components/AppDownload';
import LocationsList from '../shared/components/Locations/List';
import Process from '../shared/components/Process';
import Professionals from '../shared/components/Testimonial/Professionals';
import CtaWrapper from '../shared/components/CtaBlocks/CtaWrapper';
import CtaBlock from '../shared/components/CtaBlocks/CtaBlock';
import Faq from '../shared/components/Collapse/Faq';

export const query = graphql`
  {
    page: contentfulPage(slug: { eq: "locations" }) {
      title
      slug
      frequentlyAskedQuestions {
        id
        title
        childContentfulAccordionBodyRichTextNode {
          id
          json
        }
        __typename
      }
      cleaningProfessionals {
        id
        name
        image {
          file {
            url
            fileName
            contentType
          }
        }
        childContentfulCarouselTextRichTextNode {
          id
          json
        }
        __typename
      }
    }
    locations: allContentfulLocations(sort: { fields: [sortOrder], order: ASC }) {
      nodes {
        id
        location
        slug
        areasCovered
        heroImage {
          fixed(width: 690, height: 250) {
            ...GatsbyContentfulFixed
          }
          file {
            url
          }
        }
      }
    }
  }
`;

const About = ({ data }) => (
  <Layout url="locations" darkMode>
    <SEO title="Locations" />
    <Hero image={TyneBridge} heading="Locations" showReviews>
      <p>
        Newcastle Upon Tyne is home to Maidupnorth with our main office based in the Ouseburn
        Valley. Our band of Mighty Maids cover Newcastle, North Tyneside, Gateshead, Ponteland,
        Darras Hall and surrounding areas.
      </p>
    </Hero>
    <PressReviews />
    <br />
    <LocationsList locations={data.locations} />
    <Process />
    <CtaWrapper>
      <CtaBlock title="Local Company">
        <p>
          Launched on the Millenium Bridge, Quayside on the 18th of January 2016 we are now
          recognised throughout the North East as a professional 5 star cleaning company that
          believes in working alongside and complementing local services. Our main office is based
          in The Ouseburn Valley on Stepney rd in Newcastle Upon Tyne. We are a friendly bunch and a
          hearty welcome is awaiting all our customers whom we consider an extension of our
          Maidupnorth family.
        </p>
      </CtaBlock>
      <CtaBlock title="Local Cleaners">
        <p>
          Our Mighty Maids have a solid working knowledge of their local areas and are generally
          assigned end of tenancy and regular cleans within a 5 mile radius of their own home
          address. We believe strongly that residential clients benefit from having the same
          familiar cleaner attending on a weekly or fortnightly frequency allowing trust, quality
          and understanding to develop positively. Being a Maidupnorth cleaner is being proud to
          serve your local community.
        </p>
      </CtaBlock>
      <CtaBlock title="Supporting Local Causes">
        <p>
          As a caring, cleaning company we take great pleasure from supporting local charities and
          causes. Our ‘Gift A Clean’ service provides free cleans to various projects helping
          vulnerable residents and families within our surrounding areas. To date we have helped
          disability football clubs, food banks, youth clubs, senior citizen groups, adults with
          learning difficulties, mental health groups, vulnerable young women and our lovely
          neighbours at Ouseburn Farm.
        </p>
      </CtaBlock>
    </CtaWrapper>
    <Professionals data={data.page.cleaningProfessionals} />
    <ContainerFixed>
      <Faq data={data.page.frequentlyAskedQuestions} />
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

export default About;
